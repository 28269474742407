// Here you can add other styles
@font-face {
    font-family: calibri;
    src: url(./Calibri-Bold.TTF);
}

.required {
    &::after {
        content: ' *';
        color: red;
    }
}

.center-table {
    vertical-align: middle;
}

.table-border-print {
    border-left: 0 !important;
    border-right: 0 !important;
}

.font-calibri {
    font-family: calibri;
}

@media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  }